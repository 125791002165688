import { cn } from "@nephroflow/design-system/styling/utils";

import {
  CheckboxCheckedIcon as CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedIcon,
  type IconProps,
} from "./icon";

function CheckboxIcon({
  indeterminate = false,
  checked = false,
  disabled = false,
  className,
  ...props
}: {
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
} & IconProps) {
  const active = checked || indeterminate;
  const Icon = indeterminate ? CheckboxIndeterminateIcon : checked ? CheckboxCheckedIcon : CheckboxUncheckedIcon;

  return (
    <Icon
      {...props}
      className={cn(
        "rounded transition-colors",
        {
          "text-blue-100 hover:text-blue-110 [&:has(~input:hover)]:text-blue-110": active && !disabled,
          "text-blue-50 hover:text-blue-60 [&:has(~input:hover)]:text-blue-60": !active && !disabled,
          "cursor-not-allowed text-gray-20": disabled,
        },
        className,
      )}
    />
  );
}

export { CheckboxIcon };
